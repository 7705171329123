const NavItems = [
  {
    name: "Jungle",
    location: "/brain",
  },
  {
    name: "Weekly Review",
    location: "/brain/weekly-reviews",
  },
  {
    name: "About",
    location: "/brain/how-it-works"
  },
];

export default NavItems;
